import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import BlogsCard from "../components/BlogContent/BlogsCard"
import useEffectBlogDetails from '../hooks/useEffectBlogDetails';

const Blog = () => {
  const {
    posts
  } = useEffectBlogDetails();
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Blog"
        homePageText="Inicio"
        homePageUrl="/"
        activePageText="Blog"
      />
      <BlogsCard
        posts={posts}
      />
      <Footer />
    </Layout>
  )
}

export default Blog
