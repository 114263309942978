import React, { useCallback, useMemo } from 'react';

import { useQueryParamString } from 'react-use-query-param-string';

const BlogsCard = ({ posts = [] }) => {
  const [page, setPage] = useQueryParamString('page', '1');
  const [tag, setTag] = useQueryParamString('tag', '');
  const [category, setCategory] = useQueryParamString('category', '');
  const [maxPost, setMaxPost] = useQueryParamString('maxPost', '6');

  const actualPage = useMemo(() => (page * 1), [page]);
  const maxPerPage = useMemo(() => (maxPost * 1), [maxPost]);

  let postPages = useMemo(() => {
    let items = posts;
    if (tag != '') {
      items = items.filter(p => p.tags.includes(decodeURI(tag)));
    }
    if (category != '') {
      items = items.filter(p => p.category === decodeURI(category));
    }
    return items;
  }, [posts, tag, category, maxPerPage, actualPage]);

  let postPage = useMemo(() => {
    let items = postPages;
    if (actualPage >= 2) {
      const desde = ((actualPage * maxPerPage) - maxPerPage) -1;
      items = items.slice(desde, desde + maxPerPage);
    } else {
      items = items.slice(0,maxPerPage);
    }
    return items;
  }, [postPages, tag, category, maxPerPage, actualPage]);

  const totalPosts = useMemo(() => {
    return postPages.length;
  }, [postPages]);



  const maxPages = useMemo(() => {
    const maxPages = (totalPosts / maxPerPage) + (totalPosts % maxPerPage > 0 ? 1 : 0);
    return maxPages;
  }, [totalPosts, maxPerPage]);
  let pages = useMemo(() => {
    let pages = [];
    for (let i = 1; i <= maxPages; i++) {
      pages.push(i);
    }
    return pages;
  }, [maxPages]);



  const nextPage = useCallback(function() {
    setPage(actualPage + 1);
  }, [actualPage]);

  const prevPage = useCallback(function() {
    setPage(actualPage - 1);
  }, [actualPage]);

  return (
    <div className="blog-area ptb-100">
      <div className="container">
        <div className="row">
          {postPage.map((post, i) => {
            return (
              <div
                className="col-lg-4 col-md-6"
                key={'b-' + i}
              >
                <div className="single-blog-post bg-fffbf5">
                  <div className="post-image">
                    <a
                      href={'/articulo/?id=' + post.id + '&title=' +
                        post.title.replaceAll(' ', '-')}
                    >
                      <img
                        src={post.image}
                        alt="image"
                      />
                    </a>
                  </div>

                  <div className="post-content">
                    <ul className="post-meta d-flex justify-content-between align-items-center">
                      <li>
                        <div className="post-author d-flex align-items-center">
                          <span>{post.category}</span>
                        </div>
                      </li>
                      <li>
                        <i className="flaticon-calendar"></i> {post.date}
                      </li>
                    </ul>
                    <h3>
                      <a
                        href={'/articulo/?id=' + post.id + '&title=' +
                          post.title.replaceAll(' ', '-')}
                      >
                        {post.title}
                      </a>
                    </h3>
                  </div>
                </div>
              </div>);
          })
          }
          <div className="col-lg-12 col-md-12">
            <div className="pagination-area text-center">
              <button
                onClick={prevPage}
                className="prev page-numbers btn border-none"
                disabled={actualPage == 1}
              >
                <i className="bx bx-chevrons-left"></i>
              </button>
              {pages.map((i) => {
                return (
                  <button
                    className={'page-numbers btn border-none' +
                      ((actualPage == i ? 'current' : ''))}
                    onClick={() => {
                      setPage(i);
                    }}
                    key={'p-' + i}
                    disabled={actualPage == i}
                  >
                    {i}
                  </button>
                );
              })}

              <button
                onClick={nextPage}
                className="next page-numbers btn border-none"
                disabled={actualPage == maxPages}
              >
                <i className="bx bx-chevrons-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsCard;
